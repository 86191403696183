import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/serializers"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"
import PageOpening from "../components/pageOpening"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      sanityPages(id: { eq: "133553ec-403f-5452-bfa6-dfb08839f70e" }) {
        seo {
          seo_title
          meta_description
        }
        title
        _rawBody
        _rawSubtitle
        templateList
        mainImage {
          alt
          video
          image {
            asset {
              fluid(maxWidth: 600) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      {data.sanityPages.seo && (
        <SEO
          title={data.sanityPages.seo.seo_title}
          metaDescription={data.sanityPages.seo.meta_description}
          seoTitle={data.sanityPages.seo.seo_title}
        />
      )}
      <div className="sub_header">
        <Link activeClassName="active" to="/om-os/">
          Om os
        </Link>
        <Link activeClassName="active" to="/team/">
          Team
        </Link>
        <Link activeClassName="active" to="/moed-vores-medarbejdere/">
          Mød vores medarbejdere
        </Link>
      </div>
      <section className="index">
        <PageOpening data={data} />

        <BlockContent
          className={data.sanityPages.templateList}
          blocks={data.sanityPages._rawBody}
          projectId="ppd47c0u"
          dataset="production"
          serializers={serializers}
        />
      </section>
      <ContactForm></ContactForm>
    </Layout>
  )
}

export default IndexPage
